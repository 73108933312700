<template>
  <div>
    <v-progress-circular v-if="loading"></v-progress-circular>
    <v-icon v-else-if="error">$vuetify.icons.error</v-icon>
    <template v-else-if="store">
      <v-avatar v-if="store.avatarUrl" :size="size">
        <v-img :src="store.avatarUrl" :alt="store.name" />
      </v-avatar>
      <store-brand-avatar
        v-else
        :brand="store.brand"
        :name="store.name"
        :size="size"
        :store-initials="store.initials"
      />
    </template>
    <v-avatar v-else :size="size">
      <v-progress-circular indeterminate />
    </v-avatar>
  </div>
</template>

<script>
import { STORE_AVATAR_INFO } from "@/graphql/store/queries.gql";

export default {
  components: {
    StoreBrandAvatar: () => import("./StoreBrandAvatar"),
  },
  props: {
    storeId: {
      type: [Number, String],
      default: "",
    },
    size: {
      type: [Number, String],
      default: "",
    },
  },
  apollo: {
    store: {
      query: STORE_AVATAR_INFO,
      fetchPolicy: "cache-only",
      variables() {
        return {
          id: this.storeId
            ? this.storeId.toString()
            : `no-store-avatar-storeid`,
        };
      },
      skip() {
        return !this.storeId;
      },
    },
  },
  computed: {
    loading() {
      return this.$apollo.queries.store.loading;
    },
    error() {
      return this.$apollo.queries.store.error;
    },
  },
};
</script>
